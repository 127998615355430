<div [ngStyle.xs]="{display:'none'}">
  <!--<button mat-button [matMenuTriggerFor]="menu">
    Idioma
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button (click)="handlerCultureLanguage('pt-BR')" mat-menu-item>PT</button>
    <button (click)="handlerCultureLanguage('en-US')" mat-menu-item>EN</button>
    <button (click)="handlerCultureLanguage('es-ES')" mat-menu-item value="es-ES">ES</button>
  </mat-menu>-->
  <span class="mat-body-1">
  {{language.toUpperCase()}}
</span>
  <span class="ml-10 mr-8">{{'welcome' | translate}}, {{modelUser.name}}</span>
</div>
